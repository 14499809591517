import React, { useState } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Slider from "react-slick";
import { SubpageHeader } from "../components";
import { StaticQuery, graphql } from "gatsby";

const sliderMain = [
  require("../assets/img/chill1.jpg"),
  require("../assets/img/chill2.jpg"),
  require("../assets/img/chill3.jpg"),
  require("../assets/img/fruits1.jpg"),
  require("../assets/img/trip1.jpg"),
  require("../assets/img/trip2.jpg"),
  require("../assets/img/trip3.jpg"),
  require("../assets/img/trip4.jpg"),
  require("../assets/img/football1.jpg"),
  require("../assets/img/football2.jpg"),
  require("../assets/img/pizza.jpg"),
];

const Praca = ({ jobList }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Layout
      seo={{
        title: "Praca marketing Kraków",
        description:
          "Znajdź swoją wymarzoną pracę w dziale marketingu! Szukasz pracy jako PPC Specialist, Social Media Specialist lub Customer Success Specialist? Sprawdź nasze ogłoszenia i aplikuj już dzisiaj. Dołącz do zespołu ekspertów i rozwijaj swoje umiejętności w dynamicznym środowisku pracy. Przekonaj się, jak wiele możesz osiągnąć z nami.",
        href: "praca",
        lang: "pl",
      }}
    >
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <SubpageHeader title="praca" />
      <section className="work-section-1">
        <div className="jobs-button-container">
          <a
            href="#oferty-pracy"
            className="btn btn-box jobs-button-container__button"
          >
            sprawdź aktualne <strong>oferty pracy</strong>
          </a>
        </div>
        <div className="row row--1">
          <div className="col-lg-4 offset-lg-2 col-md-6 work-section-1__img-col img-col">
            <div className="img-wrapper img-wrapper--1">
              <img
                src={require("../assets/img/work-img-1.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-6 work-section-1__text-col text-col">
            <div className="text-container">
              <h2 className="text-container__title">Ciesz się pracą</h2>
              <p className="text-container__text">
                Biznes to nie tylko pieniądze i targety. To ludzie, którzy ten
                biznes tworzą. Jesteśmy specjalistami z różnymi doświadczeniami
                i umiejętnościami, ale przede wszystkim ludźmi - ze swoimi
                pasjami, marzeniami i poczuciem humoru. Łączy nas jeden cel -
                pomoc naszym klientom w rozwoju ich biznesu. Nic nie pomaga nam
                bardziej w realizacji tego celu, niż wspierająca, przyjacielska
                atmosfera, którą da się poczuć przekraczając już próg naszego
                biura.
              </p>
            </div>
          </div>
        </div>
        <div className="row flex-row-reverse row--2">
          <div className="col-md-6 work-section-1__img-col img-col img-col--reversed">
            <div className="img-wrapper img-wrapper--2">
              <img
                src={require("../assets/img/work-img-2.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-6 work-section-1__text-col text-col">
            <div className="text-container">
              <h2 className="text-container__title">Work-life balance</h2>
              <p className="text-container__text">
                Wspieramy harmonię pomiędzy Twoim życiem zawodowym i prywatnym.
                Nic nie będzie cieszyć nas bardziej, niż Twój wypoczęty uśmiech
                i gotowość na nowy dzień pracy. W zależności od Twojego
                stanowiska pracy będziesz pracować w trybie hybrydowym, zdalnym
                lub elastycznym. Potrzebujesz się dłużej wyspać, wyjść
                wcześniej? Dajemy Ci taką możliwość.
              </p>
            </div>
          </div>
        </div>
        <div className="row row--3">
          <div className="col-xl-4 offset-xl-2 col-md-6 work-section-1__img-col img-col">
            <div className="img-wrapper img-wrapper--3">
              <img
                src={require("../assets/img/work-img-3.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-6 work-section-1__text-col text-col">
            <div className="text-container">
              <h2 className="text-container__title">Wygrywaj z nami</h2>
              <p className="text-container__text">
                Bierz udział w projektach na miarę Twoich ambicji. Jako
                doświadczona agencja z najwyższym odznaczeniem Google - Google
                Premier Partner - zapewnimy Tobie z rozwojowe projekty o różnej
                skali, wspierające stały rozwój Twojej wiedzy i umiejętności.{" "}
                <br />
                <br />
                <strong>Przeczytaj więcej o naszej filozofii pracy </strong>
              </p>
              <div className="text-container__inner-action">
                <Link to="/win-win/" className="btn btn-line">
                  filozofia win-win
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work-section-2 work-section-2--culture">
        <div className="page-header">
          <h2>Kultura naszej organizacji</h2>
        </div>
        <div className="page-header">
          <h3>Kultura klanu</h3>
        </div>
        <div className="work-section-2--culture__text">
          <div className="work-section-2--culture__text__item">
            <p>
              Każda firma jest inna, każda cechuje się innymi założeniami i
              posiada swoje wyjątkowe DNA.  Jakie jest nasze? Wykonanie badania
              dot. kultury organizacyjnej w naszej agencji jasno wskazały na to,
              że WHEN jest organizacją reprezentującą kulturę
              klanu. Jednocześnie wyniki pokazały, że jest to kultura
              organizacyjna najbardziej pożądana przez naszych pracowników. Co
              to oznacza? 
            </p>
          </div>
          <div className="work-section-2--culture__text__item">
            <p>
              Kulturę klanu reprezentują przyjazne miejsca pracy, w których
              relacje pracowników przypominają wielką rodzinę. Osoby na
              kierowniczych stanowiskach przyjmują rolę doradców, dając
              pracownikom poczucie stabilności i bezpieczeństwa. Organizacja z
              kulturą klanu cechuje się oddaniem tradycji oraz wysokim poziomem
              lojalności pracowników. Pracownicy czują, że mają korzyści z bycia
              zaangażowanymi, ponieważ dzięki temu zaangażowaniu zyskują rozwój
              osobisty i wsparcie. Tu najważniejsza jest praca zespołowa i
              rozwój zasobów ludzkich.
            </p>
          </div>
        </div>
        <h3>Jak to przekłada się na naszą pracę w praktyce?</h3>
        <div className="icons-container">
          <div className="row">
            <div className="col-md-2 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-relation.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--1"
                />
                <p className="single-icon__title">Relacje</p>
                <p className="single-icon__text">
                  najważniejsze są dla nas pozytywne relacje i atmosfera pracy,
                  tym bardziej w dobie pracy zdalnej
                </p>
              </div>
            </div>
            <div className="col-md-2 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-stabilization.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--4"
                />
                <p className="single-icon__title">Stabilizacja</p>
                <p className="single-icon__text">
                  nasi pracownicy czują stabilność i bezpieczeństwo w pracy, co
                  przekłada się na niską rotację i wieloletnie zatrudnienie
                </p>
              </div>
            </div>
            <div className="col-md-2 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-help.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--2"
                />
                <p className="single-icon__title">Pomoc</p>
                <p className="single-icon__text">
                  pomagamy sobie wzajemnie i pracujemy zespołowo
                </p>
              </div>
            </div>
            <div className="col-md-2 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-respect.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--3"
                />
                <p className="single-icon__title">Szacunek</p>
                <p className="single-icon__text">
                  szanujemy innych pracowników i ich pracę
                </p>
              </div>
            </div>
            <div className="col-md-2 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-values.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--3"
                />
                <p className="single-icon__title">Wartości</p>
                <div className="single-icon__text">
                  <p>
                    wyznajemy podobne wartości, troszczymy się o siebie
                    wzajemnie i przywiązujemy się do siebie
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="work-section-2">
        <div className="page-header">
          <h2>Lokalizacja</h2>
        </div>
        <div className="row">
          <div className="col-lg-5 offset-lg-1 col-md-6 work-section-2__img-col">
            <img
              src={require("../assets/img/work-location.png")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 work-section-2__text-col text-col">
            <h3 className="text-col__title">
              Korzystaj <br /> z uroków Krakowa
            </h3>
            <p className="text-col__desc">
              Żyjemy w pięknym mieście - korzystajmy z jego uroków. Nasze biuro
              znajduje się w samym centrum Krakowa, przy ul. Chopina. Spotykaj
              się ze znajomymi, odpoczywaj, buszuj w sklepach. Znajdziesz w
              pobliżu wszystko, czego potrzebujesz dla Twojej wygody i komfortu
              Twojego życia.
            </p>
            <ul>
              <li>
                <strong>1,5 km</strong> od Rynku Głównego
              </li>
              <li>
                <strong>2 km</strong> od Galerii Krakowskiej i Dworca Głównego
              </li>
              <li>
                <strong>400 m</strong> od Parku Krakowskiego
              </li>
            </ul>
            <p>Najbliższy przystanek: Plac Inwalidów, Czarnowiejska</p>
          </div>
        </div>
      </section>
      <section className="work-section-3">
        <div className="page-header">
          <h2>Twoje benefity</h2>
        </div>
        <div className="icons-container">
          <div className="row">
            <div className="col-md-3 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-b2b.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--1"
                />
                <p className="single-icon__title">
                  Umowa o pracę <br /> lub B2B
                </p>
              </div>
            </div>
            <div className="col-md-3 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-medic.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--2"
                />
                <p className="single-icon__title">
                  Prywatna opieka <br /> medyczna
                </p>
              </div>
            </div>
            <div className="col-md-3 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-insurance.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--3"
                />
                <p className="single-icon__title">
                  Ubezpieczenie <br /> zdrowotne
                </p>
              </div>
            </div>
            <div className="col-md-3 icon-col">
              <div className="single-icon">
                <img
                  src={require("../assets/img/ico-remote.svg")}
                  alt=""
                  className="img-fluid single-icon__icon icon icon--4"
                />
                <p className="single-icon__title">
                  Praca zdalna
                  <br />
                  lub hybrydowa
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="sliders-container">
          <div className="row row--0">
            <div className="col-lg-4 offset-lg-1 col-md-6 text-col">
              <h3 className="sliders-container__slider-title">
                Piątki
                <br />z sushi i pizzą
              </h3>
            </div>
            <div className="col-lg-4 offset-lg-2 col-md-6 slide-col">
              <div className="slider-wrapper slider-wrapper--right">
                <div>
                  <Slider {...sliderSettings}>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[10]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(10);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(10);
                        }}
                      ></div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row row--1">
            <div className="col-lg-4 offset-lg-2 col-md-6 slide-col">
              <div className="slider-wrapper slider-wrapper--left">
                <div>
                  <Slider {...sliderSettings}>
                    {/* {
                        slider1.map((image, index) => (
                          <div>
                              <div
                                className="single-slide"
                                style={{ backgroundImage: `url(${image})` }}
                                onClick={() => this.setState({ isOpen: true, photoIndex: index})}
                              >
                              </div>
                          </div>
                        )
                        )
                      } */}
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[0]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(0);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(0);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[1]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(1);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(1);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[2]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(2);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(2);
                        }}
                      ></div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 col-md-6 text-col">
              <h3 className="sliders-container__slider-title">
                Strefa chillout na <br /> tarasie zewnętrznym
              </h3>
            </div>
          </div>

          <div className="row row--2">
            <div className="col-lg-4 offset-lg-1 col-md-6 text-col">
              <h3 className="sliders-container__slider-title">
                Owocowe <br />
                poniedziałki
              </h3>
            </div>
            <div className="col-lg-4 offset-lg-2 col-md-6 slide-col">
              <div className="slider-wrapper slider-wrapper--right">
                <div>
                  <Slider {...sliderSettings}>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[3]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(3);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(3);
                        }}
                      ></div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="row row--3">
            <div className="col-lg-4 offset-lg-2 col-md-6 slide-col">
              <div className="slider-wrapper slider-wrapper--left">
                <div>
                  <Slider {...sliderSettings}>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[4]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(4);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(4);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[5]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(5);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(5);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[6]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(6);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(6);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[7]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(7);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(7);
                        }}
                      ></div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 col-md-6 text-col">
              <h3 className="sliders-container__slider-title">
                Letnie i zimowe <br /> integracje firmowe
              </h3>
            </div>
          </div>

          <div className="row row--4">
            <div className="col-lg-4 offset-lg-1 col-md-6 text-col">
              <h3 className="sliders-container__slider-title">
                Piłkarzykowe
                <br /> turnieje
              </h3>
            </div>
            <div className="col-lg-4 offset-lg-2 col-md-6 slide-col">
              <div className="slider-wrapper slider-wrapper--right">
                <div>
                  <Slider {...sliderSettings}>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[8]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(8);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(8);
                        }}
                      ></div>
                    </div>
                    <div>
                      <div
                        className="single-slide"
                        style={{
                          backgroundImage: `url(${sliderMain[9]})`,
                        }}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(9);
                        }}
                        onKeyDown={() => {
                          setIsOpen(true);
                          setPhotoIndex(9);
                        }}
                      ></div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={sliderMain[photoIndex]}
            onCloseRequest={() => setIsOpen(false)}
            nextSrc={sliderMain[(photoIndex + 1) % sliderMain.length]}
            prevSrc={
              sliderMain[
                (photoIndex + sliderMain.length - 1) % sliderMain.length
              ]
            }
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + sliderMain.length - 1) % sliderMain.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % sliderMain.length)
            }
          />
        )}
      </section>
      <span className="anchor" id="oferty-pracy"></span>
      <section className="work-section-4">
        <div className="page-header">
          <h2>Aktualnie rekrutujemy</h2>
        </div>
        <div className="jobs-container">
          {/* <Link to="/praca/sem-spiecialist/">
              <div className="single-job">
                <h3 className="single-job__title">SEM Specialist</h3>
                <p className="single-job__desc">
                  Specjalista Google i Facebooks Ads
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.105"
                  height="35.105"
                  viewBox="0 0 35.105 35.105"
                  className="single-job__arrow"
                >
                  <path
                    id="Icon_ionic-md-arrow-forward"
                    data-name="Icon ionic-md-arrow-forward"
                    d="M5.977,25.723H32.634L20.347,38.01l3.182,3.071L41.081,23.529,23.529,5.977,20.458,9.048,32.634,21.335H5.977Z"
                    transform="translate(-5.977 -5.977)"
                  />
                </svg>
              </div>
            </Link> */}
          {jobList.edges.map(({ node }) => (
            <Link to={`/praca/${node.slug}`} key={node.id}>
              <div className="single-job">
                <h3 className="single-job__title">{node.acf.job_name}</h3>
                <p
                  className="single-job__desc"
                  dangerouslySetInnerHTML={{
                    __html: node.acf.short_desc,
                  }}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35.105"
                  height="35.105"
                  viewBox="0 0 35.105 35.105"
                  className="single-job__arrow"
                >
                  <path
                    id="Icon_ionic-md-arrow-forward"
                    data-name="Icon ionic-md-arrow-forward"
                    d="M5.977,25.723H32.634L20.347,38.01l3.182,3.071L41.081,23.529,23.529,5.977,20.458,9.048,32.634,21.335H5.977Z"
                    transform="translate(-5.977 -5.977)"
                  />
                </svg>
              </div>
            </Link>
          ))}
        </div>
      </section>
      <svg className="hover-filter" xmlns="http://www.w3.org/2000/svg">
        <filter
          id="hover-filter"
          x="-10%"
          y="-10%"
          width="120%"
          height="120%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feColorMatrix
            type="matrix"
            values="1 0 0 0 0
                    1 0 0 0 0
                    1 0 0 0 0
                    0 0 0 1 0"
            in="SourceGraphic"
            result="colormatrix"
          />
          <feComponentTransfer in="colormatrix" result="componentTransfer">
            <feFuncR type="table" tableValues="0.45 0.27" />
            <feFuncG type="table" tableValues="0.1 0.9" />
            <feFuncB type="table" tableValues="0.68 0.85" />
            <feFuncA type="table" tableValues="0 1" />
          </feComponentTransfer>
          <feBlend
            mode="normal"
            in="componentTransfer"
            in2="SourceGraphic"
            result="blend"
          />
        </filter>
      </svg>
    </Layout>
  );
};
export default () => (
  <StaticQuery
    query={graphql`
      {
        jobList: allWordpressWpJobs {
          edges {
            node {
              id
              path
              slug
              title
              acf {
                job_name
                job_desc
                short_desc
              }
            }
          }
        }
      }
    `}
    render={(data) => <Praca {...data} />}
  />
);
